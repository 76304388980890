
import fetch from 'cross-fetch'
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client'

export const client = new ApolloClient({
  link: new HttpLink({
    fetch,
    headers: {
      Authorization: `Bearer ${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}`
    },
    uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID}`,
  }),
  cache: new InMemoryCache()
});